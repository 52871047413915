<template>
	<div>
		<v-card v-if="board_id" outlined height="100%">
			<QuestionBoard :prop_board_id="board_id"></QuestionBoard>
		</v-card>

		<!-- Else, show empty -->
		<NoData
			v-else
			heading="app.no_question_board_yet"
			:show_action="false"
		></NoData>
	</div>
</template>

<script>
import QuestionBoard from "@/components/shared/tools/questionBoard/Index.vue";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "QuestionBoardWork",

	data() {
		return {
			board_id: null,
		};
	},

	components: {
		QuestionBoard,
		NoData,
	},

	mounted() {
		this.redirectToProcessBoards();
	},

	methods: {
		async redirectToProcessBoards() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("whiteboard/list", {
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
				});

				if (response && response.length) {
					this.board_id = response[0].uuid;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
